import { Country } from '~/modules/address/types'
import { AddressGuestHttpApiService } from '~/modules/address/api/AddressGuestHttpApiService'
import { AddressGuestHttpApi } from '~/modules/address/interfaces/addressGuestHttpApi'
import QuickDealHttpApiService from '~/services/http/quick-deal/QuickDealHttpApiService'
import { Region } from '~/modules/address/types/Region'
import SentryLogger from '~/services/sentry/SentryLogger'

export class AddressGuestService {
  http: AddressGuestHttpApi
  httpInstance: QuickDealHttpApiService

  constructor() {
    const nuxtApp = useNuxtApp()
    this.httpInstance = nuxtApp.$qdHttpApiInstance as QuickDealHttpApiService
    this.http = new AddressGuestHttpApiService(this.httpInstance)
  }

  public getAvailableCountries(): Promise<Array<Country>> {
    return new Promise((resolve, reject) => {
      this.http.getAvailableCountries()
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(
            error,
            {
              message: 'Не удалось получить доступные страны (гостевой запрос)',
            },
          )
          reject(error)
        })
    })
  }

  public getAvailableRegions(countryId: Country['id']): Promise<Array<Region>> {
    return new Promise((resolve, reject) => {
      this.http.getAvailableRegions(countryId)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(
            error,
            {
              message: 'Не удалось получить доступные регионы (гостевой запрос)',
              countryId,
            },
          )
          reject(error)
        })
    })
  }
}
