import {ROUTES_HOME_LAYOUT_HEADER_CLASS} from '~/router/constants/Router.routesHomeLayoutHeaderClass'
import {ROUTES_NAMES} from '~/router/constants/Router.routesNames'

export const getHomeLayoutHeaderClass = (route: string | null | undefined) => {
  const defaultHeaderClass = 'bg-neutral-100'

  const headerClass = route ? ROUTES_HOME_LAYOUT_HEADER_CLASS[route as (typeof ROUTES_NAMES)[keyof typeof ROUTES_NAMES]] : null

  return headerClass ?? defaultHeaderClass
}
