import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'

type RouteNames = typeof ROUTES_NAMES

const defaultHeaderWhiteClass = 'bg-white'

export const ROUTES_HOME_LAYOUT_HEADER_CLASS: Partial<Record<RouteNames[keyof typeof ROUTES_NAMES], string>> = {
  [ROUTES_NAMES.journal]: defaultHeaderWhiteClass,
  [ROUTES_NAMES.journalNews]: defaultHeaderWhiteClass,
  [ROUTES_NAMES.events]: defaultHeaderWhiteClass,
}
