<template>
  <default-layout-template :header-class="headerClass">
    <template #header>
      <main-menu
        class="container w-full"
        :is-nav-menu-visible="!!userStore.user?.id"
        :address-service="addressService"
        is-display-show-button-when-country-is-not-selected
      />
    </template>
    <slot />
  </default-layout-template>
  <auth-modal v-model="isAuthModalVisible" />
</template>

<script setup lang="ts">
import DefaultLayoutTemplate from '~/components/app/layout/DefaultLayoutTemplate.vue'
import AuthModal from '~/modules/auth/components/AuthModal.vue'
import { MainMenu } from '~/modules/main-menu/MainMenuModule'
import { useUserStore } from '~/modules/user/store'
import { AddressService } from '~/modules/address/service/AddressService'
import { AddressGuestService } from '~/modules/address/service/AddressGuestService'
import { useAuthStore } from '~/modules/auth/AuthModule'
import useOrganisationStore from '~/modules/organisation/store'
import { getHomeLayoutHeaderClass } from '~/router/helpers/getHomeLayoutHeaderClass'

const route = useRoute()
const headerClass = computed(() => getHomeLayoutHeaderClass(route.name as string))

const userStore = useUserStore()
const organisationStore = useOrganisationStore()
const authStore = useAuthStore()

const getAddressService = () => {
  if (userStore.isUserAuthorized && organisationStore.isOrganisationStatusGranted) {
    return new AddressService()
  }
  return new AddressGuestService()
}

const addressService = getAddressService()

const isAuthModalVisible = computed({
  get: () => authStore.isDisplayAuthModal,
  set: value => authStore.setIsDisplayAuthModal(value),
})

</script>
