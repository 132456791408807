<script setup lang="ts">

import CustomModal from '~/ui/modals/CustomModal.vue'
import AuthFormLogin from '~/modules/auth/components/AuthFormLogin.vue'
import { FORM_DISPLAYING_TYPE } from '~/modules/auth/constants/Auth.formDisplayingType'
import AuthFormForgotPassword from '~/modules/auth/components/AuthFormForgotPassword.vue'
import AuthFormSignup from '~/modules/auth/components/AuthFormSignup.vue'
import { AuthService } from '~/modules/auth/service/AuthService'
import CustomCubeSpinner from '~/ui/spinners/CustomCubeSpinner.vue'
import { useAuthStore } from '~/modules/auth/store'
import { MODAL_FORM_DISPLAY_TYPE } from '~/modules/auth/constants/Auth.modalFormDisplayType'
import SentryLogger from '~/services/sentry/SentryLogger'

const props = defineProps({
  modelValue: {
    type: Boolean,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: boolean): void
}>()

const isFetching = ref(false)

const isVisible = computed({
  get: () => props.modelValue,
  set: (value: boolean) => {
    emits('update:modelValue', value)
  },
})

const authService = new AuthService()

const authStore = useAuthStore()

const currentFormType = computed({
  get: () => authStore.authModalFormType,
  set: value => {
    authStore.setAuthModalFormType(value)
  },
})

const initApp = async() => {
  isFetching.value = true
  const token: string | null | undefined = (await authService.getAccessToken())?.token

  if (token) {
    authService.initApp(token, false)
      .finally(() => {
        isFetching.value = false
        isVisible.value = false
      })
  } else {
    SentryLogger.captureMessage('Токен не был получен при попытке запроса на инит после авторизации через модалку. Инит не был выполнен')
  }
}

onUnmounted(() => {
  authStore.setAuthModalFormType(MODAL_FORM_DISPLAY_TYPE.login)
})
</script>

<template>
  <custom-modal
    v-model="isVisible"
    size="max-w-[400px] w-full"
    body-class="!p-0"
    hide-footer
    :hide-header="isFetching"
    :no-close-on-backdrop="isFetching"
  >
    <template v-if="!isFetching">
      <auth-form-login
        v-if="currentFormType === MODAL_FORM_DISPLAY_TYPE.login"
        :form-displaying-type="FORM_DISPLAYING_TYPE.modal"
        @go-to-forgot-password="currentFormType = MODAL_FORM_DISPLAY_TYPE.forgotPassword"
        @go-to-signup="currentFormType = MODAL_FORM_DISPLAY_TYPE.signup"
        @on-login="initApp"
      />
      <auth-form-forgot-password
        v-if="currentFormType === MODAL_FORM_DISPLAY_TYPE.forgotPassword"
        :form-displaying-type="FORM_DISPLAYING_TYPE.modal"
        @go-to-login="currentFormType=MODAL_FORM_DISPLAY_TYPE.login"
      />
      <auth-form-signup
        v-if="currentFormType === MODAL_FORM_DISPLAY_TYPE.signup"
        :form-displaying-type="FORM_DISPLAYING_TYPE.modal"
        @go-to-login="currentFormType=MODAL_FORM_DISPLAY_TYPE.login"
        @on-signup="initApp"
      />
    </template>
    <template v-else>
      <div class="grow flex justify-center items-center w-[350px] h-[350px]">
        <custom-cube-spinner />
      </div>
    </template>
  </custom-modal>
</template>
