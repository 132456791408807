import { HttpApiService } from '~/services/http/HttpApiService'
import { HttpHandledResponse } from '~/services/http/types/Http.handledResponse'
import { AddressGuestHttpApi } from '~/modules/address/interfaces/addressGuestHttpApi'
import { Service } from '~/services/http/Service'
import { Country } from '~/modules/address/types'
import { Region } from '~/modules/address/types/Region'

export class AddressGuestHttpApiService extends Service implements AddressGuestHttpApi {
  private endPoints = {
    firstPart: '/v4/estate-market/guest/addresses',
    getAvailableCountries: '/available-countries',
    getAvailableRegions: '/localities',
  }

  constructor(httpInstance: HttpApiService) {
    super(httpInstance)
  }

  public getAvailableCountries(): Promise<HttpHandledResponse<Array<Country>>> {
    return this.httpInstance.get(`${this.endPoints.firstPart}${this.endPoints.getAvailableCountries}`)
  }

  public getAvailableRegions(countryId: Country['id']): Promise<HttpHandledResponse<Array<Region>>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.getAvailableRegions}`, { id: countryId })
  }
}
